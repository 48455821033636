import React, { FC, Fragment, useState } from 'react';
import { GroupId } from '../entities';
import firebase from 'firebase';
import { PersonAdd, Send } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField as MuiTextField,
  withStyles,
} from '@material-ui/core';
import { ClosableDialogTitle } from './ClosableDialogTitle';

const TextField = withStyles(({
  root: {
    marginTop: 12,
  },
}))(MuiTextField);

interface Props {
  groupId: GroupId;
  onClose: () => void;
  open: boolean;
}

const InviteUserDialog: FC<Props> = ({ groupId, onClose: onCloseCallback, open }) => {
  const [inviteEmail, setInviteEmail] = useState<string | null>(null);

  const onClose = () => {
    onCloseCallback();
    setInviteEmail(null);
  };

  const sendInvite = ({ groupId }: { groupId: GroupId }) => async () => {
    const result = await firebase.functions().httpsCallable('inviteUser')({
      email: inviteEmail,
      groupId,
    });

    if (result.data !== undefined) {
      onClose();
    }

    console.log({result});
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog onClose={onClose} open>
      <ClosableDialogTitle onClose={onClose}>Invite Someone To This Group</ClosableDialogTitle>
      <DialogContent>
        <DialogContentText>
          Invite someone to this group.

          This person will be able to create new activities,
          as well as view, modify, and delete any activities or images in this group.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          label="Email"
          onChange={event => {
            setInviteEmail(event.target.value);
          }}
          type="email"
          value={inviteEmail ?? ''}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={sendInvite({ groupId })}
          startIcon={<Send />}
          variant="contained"
        >
          Send Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const InviteUserButton: FC<{ groupId: GroupId }> = ({ groupId }) => {
  const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);

  const openAddUserDialog = () => {
    setAddUserDialogVisible(true);
  };

  const closeAddUserDialog = () => {
    setAddUserDialogVisible(false);
  };

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={openAddUserDialog}
        startIcon={<PersonAdd />}
        variant="contained"
      >
        Invite A User
      </Button>
      {addUserDialogVisible && (
        <InviteUserDialog
          groupId={groupId}
          onClose={closeAddUserDialog}
          open
        />
      )}
    </Fragment>
  );
};
