import { Button, Dialog, DialogContent, DialogActions, TextField, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, Fragment, useState } from 'react';
import { GroupId } from '../entities';
import { ClosableDialogTitle } from './ClosableDialogTitle';
import { DeleteGroupButton } from './DeleteGroupButton';
import firebase from 'firebase';
import { isNil } from 'ramda';
import { MembersList } from './MembersList';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';

const Alert = withStyles(theme => ({
  message: {
    width: '100%',
  },
  root: {
    marginTop: theme.spacing(2),
  },
}))(MuiAlert);

interface Props {
  groupId: GroupId;
  groupName: string;
}

const Section = withStyles(theme => ({
  root: {
    '&:first-of-type': {
      marginTop: 0,
    },
    margin: theme.spacing(4, 0, 2),
  },
}))(({ classes, title, ...props}) => (
  <div className={classes.root}>
    <Typography gutterBottom variant="h6">{title}</Typography>
    <div {...props} />
  </div>
));

export const EditGroupButton: FC<Props> = ({ groupId, groupName }) => {
  const [name, setName] = useState(groupName);
  const [editGroupDialogVisible, setEditGroupDialogVisible] = useState(false);

  const openEditGroupDialog = () => {
    setEditGroupDialogVisible(true);
  };

  const closeEditGroupDialog = () => {
    setEditGroupDialogVisible(false);
  };

  const editGroup = async () => {
    const result = await firebase.functions()
      .httpsCallable('setGroupName')({ groupId, name });

    if (!isNil(result)) {
      closeEditGroupDialog();
    }

    console.log({ result });
  };

  return (
    <Fragment>
      <Button
        onClick={openEditGroupDialog}
        startIcon={<Edit />}
      >
        Edit Group
      </Button>

      {editGroupDialogVisible && (
        <Dialog onClose={closeEditGroupDialog} open>
          <ClosableDialogTitle onClose={closeEditGroupDialog}>Edit Group</ClosableDialogTitle>
          <DialogContent>
            <Section title="Group Details">
              <TextField
                autoFocus
                fullWidth
                label="Group Name"
                onChange={event => {
                  setName(event.target.value);
                }}
                type="text"
                value={name ?? ''}
                variant="outlined"
              />

              <Alert
                severity="warning"
                style={{
                  marginTop: 12,
                }}
                variant="outlined"
              >
                <AlertTitle>Delete Entirely</AlertTitle>
                <div>
                  You may delete this group entirely.
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 16,
                  }}
                >
                  <DeleteGroupButton groupId={groupId} groupName={groupName} />
                </div>
              </Alert>
            </Section>

            <Section title="Group Members">
              <MembersList
                groupId={groupId}
                showDelete
              />
            </Section>



          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditGroupDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={editGroup}
              startIcon={<Edit />}
              variant="contained"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};
