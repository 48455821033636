import { Card, CardActionArea, CardContent, Chip, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Category, Close, Face, SubdirectoryArrowRight } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Activity as ActivityType, Filters } from '../entities';
import { selectDebugMode, setFilters as setFiltersAction } from '../store/configSlice';
import { selectImageFullPath } from '../store/firebaseSelectors';
import MuiImage from 'material-ui-image';
import { isEmpty } from 'ramda';
import { EditForm } from './EditForm';
import { InlineCode } from './InlineCode';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

interface Props {
  activity: ActivityType;
}

export const ActivityCard: FC<Props> = ({ activity }) => {
  const {
    category,
    downloadURL,
    groupId,
    height,
    imageId,
    name,
    subCategory,
    subjects,
    width,
  } = activity;
  const [modalVisible, setModalVisible] = useState(false);
  const debugMode = useSelector(selectDebugMode);
  const firebase = useFirebase();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const setFilters = (filters: Filters) => () => {
    dispatch(setFiltersAction(filters));
  };

  const deleteActivity = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    const path = selectImageFullPath({ groupId, imageId });
    firebase.deleteFile(path, path)
      .then(result => {
        console.log('successfully deleted', result);
      })
      .catch(result => {
        console.log('error deleting file', result);
      });
  };

  return (
    <div>
      <EditForm
        activity={activity}
        dialogProps={{
          onClose: () => {
            setModalVisible(false);
          },
          open: modalVisible,
        }}
        handleDelete={deleteActivity}
        setActivityItem={console.log}
      />

      <Card>
        <CardActionArea
          onClick={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <MuiImage
            animationDuration={1000}
            aspectRatio={width / height}
            color={theme.palette.background.default}
            src={downloadURL}
            title={name ?? ''}
          />
          {debugMode && (
            <IconButton
              onClick={deleteActivity}
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
              }}
            >
              <Close
                style={{
                  fill: 'white',
                  paintOrder: 'stroke',
                  stroke: 'black',
                  strokeWidth: 3,
                }}
              />

            </IconButton>
          )}
        </CardActionArea>
        <CardContent>
          <Typography component="h2" gutterBottom variant="h5">{name}</Typography>

          <div className={classes.root}>
            <Chip
              icon={<Category />}
              label={category}
              onClick={setFilters({ category })}
              title={`category: ${category}`}
              variant="outlined"
            />

            <Chip
              icon={<SubdirectoryArrowRight />}
              label={subCategory}
              onClick={setFilters({ category, subCategory })}
              title={`sub-category: ${subCategory}`}
              variant="outlined"
            />

            {isEmpty(subjects) ? null : (
              subjects?.map(subject => (
                <Chip
                  icon={<Face />}
                  key={subject}
                  label={subject}
                  title={`subject: ${subject}`}
                  variant="outlined"
                />
              ))
            )}
          </div>

          {debugMode && <InlineCode>{imageId}</InlineCode>}
        </CardContent>
      </Card>
    </div>
  );
};
