import React, { FC, Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
  Button,
  Typography,
} from '@material-ui/core';
import { GroupId, UserId } from '../entities';
import { Clear, RemoveCircle } from '@material-ui/icons';
import { ClosableDialogTitle } from './ClosableDialogTitle';
import { WarningButton } from './utils';
import firebase from 'firebase';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { selectGroupName } from '../store/firebaseSelectors';

interface Props {
  displayName: string;
  groupId: GroupId;
  userId: UserId;
}

export const RemoveUserIconButton: FC<Props> = ({ displayName, groupId, userId }) => {
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
  const groupName = useSelector(selectGroupName(groupId));

  const openDeleteUserDialog = () => {
    setDeleteUserDialogVisible(true);
  };

  const closeDeleteUserDialog = () => {
    setDeleteUserDialogVisible(false);
  };

  const deleteUser = async () => {
    const result = await firebase.functions()
      .httpsCallable('removeUserFromGroup')({ groupId, userId });

    if (!isNil(result)) {
      console.log('deleted user', result);
      closeDeleteUserDialog();
    }
  };

  return (
    <Fragment>
      <Tooltip title="Remove this user">
        <IconButton
          edge="end"
          onClick={openDeleteUserDialog}
        >
          <Clear />
        </IconButton>
      </Tooltip>
      {deleteUserDialogVisible && (
        <Dialog onClose={closeDeleteUserDialog} open>
          <ClosableDialogTitle onClose={closeDeleteUserDialog}>Remove {displayName}</ClosableDialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove <Typography color="secondary" display="inline">{displayName}</Typography> from the <Typography color="secondary" display="inline">{groupName}</Typography> group?
            </DialogContentText>
            <DialogContentText>
              They will lose access to all content and activities in this group.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteUserDialog}>
              Cancel
            </Button>
            <WarningButton
              onClick={deleteUser}
              startIcon={<RemoveCircle />}
              variant="contained"
            >
              Remove
            </WarningButton>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};
