import React, { FC, ReactElement, ReactNode, useState } from 'react';

import {
  AppBar as MuiAppBar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useScrollTrigger,
  withStyles,
} from '@material-ui/core';
import { SignInScreen } from './SignInScreen';
import { Settings, Home, Menu as MenuIcon, InvertColors } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { UserProfileMenu } from './UserProfileMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectThemeMode, toggleThemeMode } from '../store/configSlice';

const AppBarTitle = withStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
}))(props => <Typography variant="h6" {...props} />);


const HideOnScroll: FC<{ children: ReactElement<any, any> }> = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

interface ListItemLinkProps {
  to: string;
  label: string;
  icon: ReactNode;
}

const ListItemLink: FC<ListItemLinkProps> = ({ icon, to, label }) => (
  <ListItem button component={Link} to={to}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
);

export const AppBar = () => {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const theme = useSelector(selectThemeMode);

  const toggleTheme = () => {
    dispatch(toggleThemeMode());
  };

  const openDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <div>
      <SignInScreen />

      <HideOnScroll>
        <MuiAppBar>

          <Toolbar>
            <IconButton
              aria-label="open drawer"
              color="inherit"
              edge="start"
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>

            {drawerVisible && (
              <SwipeableDrawer
                anchor="left"
                onClose={closeDrawer}
                onOpen={openDrawer}
                open
              >
                <div
                  onClick={closeDrawer}
                  onKeyDown={closeDrawer}
                  role="presentation"
                  style={{ width: 240 }}
                >
                  <List>
                    <ListItemLink
                      icon={<Home />}
                      label="Home"
                      to="/"
                    />
                    <ListItemLink
                      icon={<Settings />}
                      label="Config"
                      to="/config"
                    />
                    <Divider />
                    <ListItem
                      button
                      onClick={toggleTheme}
                    >
                      <ListItemIcon><InvertColors /></ListItemIcon>
                      <ListItemText>{theme === 'dark' ? 'Light' : 'Dark'} Mode</ListItemText>
                    </ListItem>
                  </List>
                </div>
              </SwipeableDrawer>
            )}

            <AppBarTitle>Activity Menu</AppBarTitle>

            <UserProfileMenu />

          </Toolbar>
        </MuiAppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
};
