import { Button, Dialog, DialogContentText, DialogContent, DialogActions } from '@material-ui/core';
import { Delete, DeleteForever } from '@material-ui/icons';
import React, { FC, Fragment, useState } from 'react';
import { GroupId } from '../entities';
import { ClosableDialogTitle } from './ClosableDialogTitle';
import { WarningButton } from './utils';
import firebase from 'firebase';

interface Props {
  groupId: GroupId;
  groupName: string;
}

export const DeleteGroupButton: FC<Props> = ({ groupId, groupName }) => {
  const [deleteGroupDialogVisible, setDeleteGroupDialogVisible] = useState(false);

  const openDeleteGroupDialog = () => {
    setDeleteGroupDialogVisible(true);
  };

  const closeDeleteGroupDialog = () => {
    setDeleteGroupDialogVisible(false);
  };

  const deleteGroup = (groupId: GroupId) => async () => {
    await firebase.functions().httpsCallable('deleteGroup')({ groupId });
    closeDeleteGroupDialog();
  };

  return (
    <Fragment>
      <Button
        onClick={openDeleteGroupDialog}
        startIcon={<Delete />}
      >
        Delete Group
      </Button>

      {deleteGroupDialogVisible && (
        <Dialog onClose={closeDeleteGroupDialog} open>
          <ClosableDialogTitle onClose={closeDeleteGroupDialog}>Delete Group</ClosableDialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the {groupName} group?</DialogContentText>
            <DialogContentText>
              All data will be permanently deleted, including photos and all information about activities
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteGroupDialog}>
              Cancel
            </Button>
            <WarningButton
              onClick={deleteGroup(groupId)}
              startIcon={<DeleteForever />}
              variant="contained"
            >
              Delete Forever
            </WarningButton>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};
