import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  withStyles,
} from '@material-ui/core';
import { isNil } from 'ramda';
import React, { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase';

import { selectDisplayName } from '../store/firebaseSelectors';
import { ClosableDialogTitle } from './ClosableDialogTitle';
import { GroupAdd } from '@material-ui/icons';

const Dialog = withStyles(({
  paper: {
    minWidth: 375,
  },
}))(MuiDialog);

const MainButton = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))(Button);

interface Props {
  onClose: () => void;
  open: boolean;
}

const CreateGroupDialog: FC<Props> = ({ onClose: onCloseCallback, open }) => {
  const displayName = useSelector(selectDisplayName);

  const defaultName = (displayName?: string | null) => (
    isNil(displayName) ? null : `${displayName}'${displayName?.endsWith('s') ?? false ? '' : 's'} group`
  );
  const [newGroupName, setNewGroupName] = useState<string | null>(defaultName(displayName));

  const onClose = () => {
    onCloseCallback();
    setNewGroupName(null);
  };

  const createGroup = async () => {
    const result = await firebase.functions().httpsCallable('createGroup')({
      name: newGroupName,
    });
    console.log({ result });
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog onClose={onClose} open>
      <ClosableDialogTitle onClose={onClose}>Create a new Group</ClosableDialogTitle>
      <DialogContent>
        <DialogContentText>Give your new group a name</DialogContentText>
        <TextField
          autoFocus
          fullWidth
          label="Group Name"
          margin="dense"
          onChange={event => {
            setNewGroupName(event.target.value);
          }}
          type="text"
          value={newGroupName ?? ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={createGroup}
          startIcon={<GroupAdd />}
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>

  );
};


export const CreateGroupButton: FC = () => {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  const openCreateDialog = () => {
    setCreateDialogVisible(true);
  };

  const closeCreateDialog = () => {
    setCreateDialogVisible(false);
  };

  return (
    <Fragment>
      <MainButton
        onClick={openCreateDialog}
        startIcon={<GroupAdd />}
      >
        Create A New Group
      </MainButton>

      {createDialogVisible && (
        <CreateGroupDialog
          onClose={closeCreateDialog}
          open
        />
      )}
    </Fragment>
  );
};
