import React, { Fragment, useState } from 'react';
import { ActivityCard } from '../ActivityCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveGroup, selectFilteredActivityImages } from '../../store/firebaseSelectors';
import { Upload } from '../Upload';
import { Box, Button, Chip, Dialog as MuiDialog, DialogContent, DialogTitle, Grid as MuiGrid, GridProps, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { FilterTree } from '../FilterTree';
import { isEmpty, map, keys, isNil } from 'ramda';
import { selectFilters, setFilters as setFiltersAction } from '../../store/configSlice';
import { Filters } from '../../entities';
import { Category, SubdirectoryArrowRight } from '@material-ui/icons';
import { ClosableDialogTitle } from '../ClosableDialogTitle';

const GridWrapper = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: theme.spacing(1),
    },
  },
}))(Box);

const Grid = withStyles({
  root: {
    flexGrow: 1,
  },
})(MuiGrid);

const Dialog = withStyles({
  paper: {
    minWidth: 300,
  },
})(MuiDialog);

const GridItem = withStyles({
  root: {},
})((props: GridProps) => <MuiGrid item {...props} lg={3} md={4} sm={6} xl={2} xs={12} />);

export const ActivitiesPage = () => {
  const activeGroup = useSelector(selectActiveGroup);
  const hasGroup = activeGroup !== null && activeGroup !== undefined;
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const spacing = isSmall ? 1 : 2;

  const filteredActivities = useSelector(selectFilteredActivityImages);

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  const setFilters = (filters: Filters) => () => {
    dispatch(setFiltersAction(filters));
  };

  const filteringBy = (isNil(filters?.category) && isNil(filters?.subCategory)) ? null : (
    map(type => (filters[type] === null ? null : (
      <Chip
        icon={type === 'category' ? <Category /> : <SubdirectoryArrowRight />}
        key={`${type}: ${filters[type]}`}
        label={filters[type]}
        onClick={setFilters({ [type]: filters[type] })}
        style={{
          margin: '0 6px',
        }}
        title={`${type}: ${filters[type]}`}
      />
    )), keys(filters))
  );

  return (
    <Fragment>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: 10,
        }}
      >
        <div>
          <Button
            color="primary"
            onClick={showModal}
            variant="contained"
          >
            Filter
          </Button>
          {filteringBy && <Button onClick={setFilters({})} style={{ marginLeft: 12 }}>Clear Filter</Button>}
        </div>

        {filteringBy && (
          <div
            style={{
              display: 'flex',
              marginTop: 10,
            }}
          >
            {filteringBy}
          </div>
        )}

        {modalVisible && (
          <Dialog onClose={closeModal} open>
            <ClosableDialogTitle onClose={closeModal}>Filter Activities</ClosableDialogTitle>
            <DialogContent>
              <FilterTree />
            </DialogContent>
          </Dialog>
        )}
      </div>

      <GridWrapper>
        <Grid container spacing={spacing}>
          {hasGroup && filteredActivities?.map(activity => (activity === null ? null : (
            <GridItem key={activity.imageId}>
              <ActivityCard activity={activity} />
            </GridItem>
          )))}
        </Grid>
      </GridWrapper>

      <Upload />
    </Fragment>
  );
};
