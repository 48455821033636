import { map, pick, reduce, isEmpty, keys, whereEq, isNil } from 'ramda';
import React, { FC, Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectActivities, selectActivityCount } from '../store/firebaseSelectors';
import {
  Button,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText,
  styled,
  withStyles,
} from '@material-ui/core';
import { Filters } from '../entities';
import { selectFilters, setFilters as setFiltersAction } from '../store/configSlice';
import { Clear } from '@material-ui/icons';

const List = withStyles({
  padding: {
    paddingBottom: 0,
    paddingTop: 0,
  },
})(MuiList);

const ListItem = withStyles({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
  },
})(MuiListItem);

const Counter = styled('span')({
  display: 'flex',
  justifySelf: 'flex-end',
});

export const FilterTree: FC = () => {
  const activities = useSelector(selectActivities);
  const filters = useSelector(selectFilters);
  const activityCount = useSelector(selectActivityCount);
  const dispatch = useDispatch();

  const picks = map(pick(['category', 'subCategory']), activities ?? []);
  const tree: Record<string, string[]> = reduce((accumulator, { category, subCategory }) => {
    if (category === null) {
      return accumulator;
    }

    return {
      ...accumulator,
      [category]: Array.from(new Set([
        ...(accumulator[category] ?? []),
        subCategory,
      ])),
    };
  }, {}, picks);

  const isSelected = (select: Filters) => whereEq(select, filters);

  const setFilters = (select: Filters) => () => {
    if (isSelected(select)) {
      dispatch(setFiltersAction({}));
      return;
    }
    dispatch(setFiltersAction(select));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {!isEmpty(tree) && (
        <List dense>
          {map(category => (
            <Fragment key={category}>
              <ListItem
                button
                key={category}
                onClick={setFilters({ category })}
                selected={isSelected({ category })}
              >
                <ListItemText>{category}</ListItemText>
                <Counter>{activityCount({ category })}</Counter>
              </ListItem>
              <List dense>
                {map(subCategory => (
                  <ListItem
                    button
                    key={`${category}/${subCategory}`}
                    onClick={setFilters({ category, subCategory })}
                    selected={isSelected({ category, subCategory })}
                    style={{ paddingLeft: 48 }}
                  >
                    <ListItemText>{subCategory}</ListItemText>
                    <Counter>{activityCount({ category, subCategory })}</Counter>
                  </ListItem>
                ), tree[category].sort())}
              </List>
            </Fragment>
          ), keys(tree).sort())}

        </List>
      )}

      {(!isNil(filters?.category) || !isNil(filters?.subCategory)) && (
        <Button
          onClick={setFilters({})}
          startIcon={<Clear />}
          style={{
            marginTop: 16,
          }}
        >
          Clear Filter
        </Button>
      )}
    </div>
  );
};
