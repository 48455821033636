import React, { Fragment, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab as MuiFab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { DropzoneAreaBase as MuiDropzoneAreaBase, DropzoneDialogBaseProps } from 'material-ui-dropzone';
import { useSelector } from 'react-redux';
import { forEach, indexBy, isEmpty, keys, map, partition, prop, reduce, reject, values } from 'ramda';
import { v4 } from 'uuid';
import { useFirebase } from 'react-redux-firebase';
import { selectActiveGroup, selectUserId } from '../store/firebaseSelectors';
import { Add } from '@material-ui/icons';
import { ActivityAndFile, ActivityAndFileByImageId } from '../entities';
import { isAnnotated, onSave, readFile } from '../utils/utils';
import { ManualEntry } from '../utils/categories';
import { EditForm } from './EditForm';
import { ClosableDialogTitle } from './ClosableDialogTitle';

const DropzoneAreaBase = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}))(MuiDropzoneAreaBase);

const Fab = withStyles(theme => ({
  root: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),
  },
}))(MuiFab);

export const Upload = () => {
  const firebase = useFirebase();
  const userId = useSelector(selectUserId);
  const { groupId } = useSelector(selectActiveGroup) ?? {};
  const [dialogOpen, setDialogOpen] = useState(false);

  const [metadata, setMetadata] = useState<Record<string, ManualEntry>>({});
  const [activityItems, setActivityItems] = useState<ActivityAndFileByImageId>({});
  const setActivityItem = (activityItem: ActivityAndFile) => {
    setActivityItems({
      ...activityItems,
      [activityItem.imageId]: activityItem,
    });
  };

  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const onDrop: DropzoneDialogBaseProps['onDrop'] = files => {
    console.log('onDrop', { files });
    const [jsonFiles, imageFiles] = partition(({ type }) => type === 'application/json', files);
    Promise.all(map(imageFile => readFile(imageFile).then((data): ActivityAndFile => ({
      category: null,
      data,
      file: imageFile,
      imageId: v4(),
      name: null,
      subCategory: null,
      subjects: null,
      tags: null,
    })), imageFiles))
      .then((result: ActivityAndFile[]) => {
        const nextActivityItems = indexBy(prop('imageId'), result);
        setActivityItems(nextActivityItems);
      })
      .catch(error => {
        console.error({ error });
      });

    forEach(jsonFile => {
      const reader = new FileReader();
      reader.onload = () => {
        const { manualEntries } = JSON.parse(reader.result as string) as { manualEntries: ManualEntry[] };
        setMetadata(metadata => ({
          ...metadata,
          ...indexBy(prop('imagePath'), manualEntries),
        }));
      };
      reader.readAsText(jsonFile);
    }, jsonFiles);
  };

  const annotatedItems = reduce<ActivityAndFile, ActivityAndFileByImageId>((accumulator, activityItem) => {
    const match = metadata[activityItem.file.name];
    if (match === undefined) {
      return {
        ...accumulator,
        [activityItem.imageId]: activityItem,
      };
    }

    return {
      ...accumulator,
      [activityItem.imageId]: {
        ...activityItem,
        ...match,
      },
    };
  }, {}, values(activityItems));

  const uncategorized = reject<ActivityAndFile>(isAnnotated, values(annotatedItems));

  return (
    <Fragment>
      <Fab color="secondary" onClick={openDialog}><Add /></Fab>

      {dialogOpen && (
        <Dialog onClose={closeDialog} open>
          <ClosableDialogTitle onClose={closeDialog}>Add an Activity</ClosableDialogTitle>

          <DialogContent>
            <DropzoneAreaBase
              acceptedFiles={['image/*', 'application/json']}
              clearOnUnmount
              dropzoneText="Drag and drop an image here or click"
              fileObjects={values(activityItems)}
              filesLimit={100}
              maxFileSize={30_000_000}
              onDrop={onDrop}
              showPreviewsInDropzone={false}
            />

            {!isEmpty(activityItems) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Sub-Category</TableCell>
                    <TableCell>Subjects</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {values(annotatedItems).map(row => (
                    <TableRow key={row.imageId}>
                      <TableCell>
                        <img
                          src={typeof row.data === 'string' ? row.data : ''}
                          style={{
                            height: 100,
                            width: 100,
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.subCategory}</TableCell>
                      <TableCell>{row.subjects}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={closeDialog}>Cancel</Button>

            <Button
              color="primary"
              disabled={keys(annotatedItems).length === 0}
              onClick={onSave({
                activityItems: annotatedItems,
                firebase,
                groupId,
                onFinish: closeDialog,
                userId,
              })}
            >
              Submit
            </Button>
          </DialogActions>

        </Dialog>
      )}

      {!isEmpty(uncategorized) && (
        <EditForm
          activity={uncategorized[0]}
          autoFocus
          dialogProps={{
            open: true,
          }}
          handleDelete={console.log}
          moreExist={uncategorized.length > 1}
          setActivityItem={setActivityItem}
        />
      )}
    </Fragment>
  );
};
