import { PaletteType } from '@material-ui/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Filters } from '../entities';
import { RootState } from './store';

interface ConfigState {
  debugMode: boolean;
  filters: Filters;
  themeMode: PaletteType;
}

export const configSlice = createSlice({
  initialState: {
    debugMode: false,
    filters: {},
    themeMode: 'light',
  } as ConfigState,
  name: 'config',
  reducers: {
    setDebugMode: (state, action: PayloadAction<ConfigState['debugMode']>) => ({
      ...state,
      debugMode: action.payload,
    }),
    setFilters: (state, action: PayloadAction<ConfigState['filters']>) => ({
      ...state,
      filters: action.payload,
    }),
    setThemeMode: (state, action: PayloadAction<ConfigState['themeMode']>) => ({
      ...state,
      themeMode: action.payload,
    }),
    toggleThemeMode: state => ({
      ...state,
      themeMode: state.themeMode === 'light' ? 'dark' : 'light',
    }),
  },
});

export const { setDebugMode, setFilters, setThemeMode, toggleThemeMode } = configSlice.actions;

export const selectDebugMode = (state: RootState) => state.config.debugMode;
export const selectFilters = (state: RootState) => state.config.filters;
export const selectThemeMode = (state: RootState) => state.config.themeMode;

export const { reducer: configReducer } = configSlice;
