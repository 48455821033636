import {
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterTree } from '../FilterTree';
import { GroupCards } from '../GroupCards';
import { selectThemeMode, toggleThemeMode } from '../../store/configSlice';
import { CreateGroupButton } from '../CreateGroupButton';

const GridContainer = withStyles(({
  root: {
    paddingTop: 16,
  },
}))(Grid);

const ThemeSwitch: FC = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectThemeMode);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={theme === 'dark'}
          name="Dark Mode"
          onChange={() => {
            dispatch(toggleThemeMode());
          }}
        />
      }
      label="Dark Mode"
      labelPlacement="start"
    />
  );
};

const Title: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography component="h2" gutterBottom variant="h5">{children}</Typography>
);

export const ConfigPage: FC = () => {
  return (
    <Container maxWidth="xl">
      <GridContainer
        alignItems="center"
        container
        direction="column"
        spacing={4}
      >
        <Grid item xs={12}>
          <Title>Groups</Title>

          <GroupCards />

          <CreateGroupButton />
        </Grid>

        <Grid item style={{ minWidth: 375 }} xs={3}>
          <Title>Config</Title>
          <FilterTree />
        </Grid>

        <Grid item style={{ minWidth: 375 }} xs={3}>
          <Title>User Interface</Title>
          <ThemeSwitch />
        </Grid>
      </GridContainer>
    </Container>
  );
};
