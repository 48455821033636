import React, { StrictMode } from 'react';

import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import { App } from './components/App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import * as firebase from 'firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { ThemeProvider } from './theme';

module?.hot?.accept();

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      config={{
        useFirestoreForProfile: true,
        useFirestoreForStorageMeta: true,
        userProfile: 'users',
      }}
      createFirestoreInstance={createFirestoreInstance}
      dispatch={store.dispatch}
      firebase={firebase}
    >
      <StrictMode>
        <ThemeProvider>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StrictMode>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root'),
);
