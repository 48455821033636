import { Avatar, Button, Divider, IconButton, Menu, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { setModalVisible } from '../store/authSlice';
import { selectDebugMode } from '../store/configSlice';
import { selectActiveGroup, selectAuth, selectIsLoadingUser, selectUserIsLoggedIn } from '../store/firebaseSelectors';
import { InlineCode } from './InlineCode';

interface UserMenuProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  logout: () => void;
  menuVisible: boolean;
}

const UserMenu: FC<UserMenuProps> = ({
  anchorEl,
  handleClose,
  menuVisible,
  logout,
}) => {
  const userInfo = useSelector(selectAuth);
  const debugMode = useSelector(selectDebugMode);
  const { name: groupName, groupId } = useSelector(selectActiveGroup) ?? {};

  const { email, photoURL, displayName, uid: userId } = userInfo;

  if (!menuVisible) {
    return null;
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      id="menu-appbar"
      keepMounted
      onClose={handleClose}
      open
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: '20px 33px',
        }}
      >
        <Avatar
          alt={displayName ?? ''}
          src={photoURL ?? ''}
          style={{
            height: 76,
            marginBottom: 12,
            outline: 'none',
            width: 76,
          }}
        />
        <Typography variant="h6">{displayName}</Typography>
        <Typography
          color="textSecondary"
          style={{
            marginTop: -6,
          }}
          variant="subtitle1"
        >
          {email}
        </Typography>

        {debugMode && <InlineCode>{userId}</InlineCode>}
      </div>

      <Divider />

      {groupName !== undefined && [
        <div
          key="activeGroup"
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: 12,
            paddingTop: 12,
          }}
        >
          <Typography color="textSecondary">Active Group</Typography>
          <Typography>{groupName}</Typography>
          {debugMode && <InlineCode>{groupId}</InlineCode>}
        </div>,
        <Divider key="groupsDivider" />,
      ]}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 6,
          paddingTop: 12,
        }}
      >
        <Button onClick={logout} variant="outlined">Sign Out</Button>
      </div>
    </Menu>
  );
};

export const UserProfileMenu: FC = () => {
  const isLoadingUser = useSelector(selectIsLoadingUser);
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const userInfo = useSelector(selectAuth);

  const { photoURL, displayName } = userInfo;
  const firebase = useFirebase();

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  if (!isLoggedIn || isLoadingUser) {
    return (
      <Button
        color="inherit"
        onClick={() => {
          dispatch(setModalVisible(true));
        }}
      >
        Login
      </Button>
    );
  }

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isLoggedIn) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setMenuVisible(true);
  };

  const handleClose = () => {
    setMenuVisible(false);
  };

  const logout = () => {
    firebase.logout()
      .then(() => {
        console.log('logged out');
      })
      .catch(() => {
        console.error('trouble logging out');
      });
  };

  return (
    <div>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
        onClick={handleMenu}
      >
        <Avatar alt={displayName ?? undefined} src={photoURL ?? undefined} />
      </IconButton>
      <UserMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        logout={logout}
        menuVisible={menuVisible}
      />
    </div>
  );
};
