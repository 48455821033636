import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from './store';
import * as firebase from 'firebase';

interface User {
  displayName: string;
  email: string;
  photoURL: string;
  uid: string;
}

interface DatabaseState {
  error: SerializedError | null;
  isLoading: boolean;
  user: null | User;
}

export const addUser = createAsyncThunk<User, User>(
  'database/addUser',
  async user => {
    const database = firebase.firestore();
    await database
      .collection('users')
      .doc(user.uid)
      .set(user);

    return user;
  },
);

export const databaseSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(addUser.pending, state => ({
      ...state,
      error: null,
      isLoading: true,
    }));
    builder.addCase(addUser.rejected, (state, action) => ({
      ...state,
      error: action.error,
      isLoading: false,
    }));
    builder.addCase(addUser.fulfilled, state => ({
      ...state,
      error: null,
      isLoading: false,
    }));
  },
  initialState: {
    error: null,
    isLoading: false,
    user: null,
  } as DatabaseState,
  name: 'database',
  reducers: {
  },
});

export const selectUser = (state: RootState) => state.database.user;
export const selectUserIsLoading = (state: RootState) => state.database.isLoading;
export const selectUserError = (state: RootState) => state.database.error;

export const { reducer: databaseReducer } = databaseSlice;
