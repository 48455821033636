import React, { FC, Fragment, useState } from 'react';
import { Snackbar, Typography as MuiTypography, withStyles } from '@material-ui/core';
import * as clipboardy from 'clipboardy';
import { Alert } from '@material-ui/lab';

const Typography = withStyles(theme => ({
  root: {
    background: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: 'Monospace',
    fontSize: '75%',
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.25, 0.5),
    whiteSpace: 'nowrap',
  },
}))(MuiTypography);

export const InlineCode: FC<{ children: string }> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const copyToClipboard = async () => {
    await clipboardy.write(children);
    openSnackbar();
  };

  return (
    <Fragment>
      <Typography
        component="code"
        onClick={copyToClipboard}
      >
        {children}
      </Typography>

      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        open={snackbarOpen}
      >
        <Alert severity="success">successfully copied `{children}` to the clipboard</Alert>
      </Snackbar>
    </Fragment>
  );
};
