import React, { FC, ReactNode } from 'react';
import { createMuiTheme, MuiThemeProvider, PaletteType } from '@material-ui/core';
import { selectThemeMode } from './store/configSlice';
import { useSelector } from 'react-redux';

const lightPrimary = '#3a7fb7';
const lightSecondary = '#35a869';

const darkPrimary = '#3b556a';
const darkSecondary = '#3b6a50';

const theme = (themeMode: PaletteType) => createMuiTheme({
  palette: {
    primary: {
      main: themeMode === 'dark' ? darkPrimary : lightPrimary,
    },
    secondary: {
      main: themeMode === 'dark' ? darkSecondary : lightSecondary,
    },
    type: themeMode,
  },
});

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const themeMode = useSelector(selectThemeMode);

  return (
    <MuiThemeProvider theme={theme(themeMode)}>
      {children}
    </MuiThemeProvider>
  );
};
