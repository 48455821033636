import React from 'react';
import { Form } from 'react-final-form';
import {
  TextField as MuiRffTextField,
  Autocomplete as MuiRffAutocomplete,
  TextFieldProps,
} from 'mui-rff';
import { ActivityAndFile, ActivityItem } from '../entities';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { isNil } from 'ramda';
import { Delete, Done, ImageSearch } from '@material-ui/icons';
import { format } from 'date-fns';
import { ClosableDialogTitle } from './ClosableDialogTitle';

const TextField = withStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}))((props: TextFieldProps) => <MuiRffTextField variant="outlined" {...props} />);

const Autocomplete = withStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}))(MuiRffAutocomplete);


interface Props {
  autoFocus?: boolean;
  activity: ActivityAndFile | ActivityItem;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dialogProps?: DialogProps;
  moreExist?: boolean;
  setActivityItem: (activityItem: ActivityAndFile) => void;
}

const validate = ({ name, category, subCategory }: Omit<ActivityItem, 'imageId'>) => {
  if (isNil(name)) {
    return { name: 'Gotta have a name' };
  }
  if (isNil(category)) {
    return { category: 'Gotta have a category' };
  }
  if (isNil(subCategory)) {
    return { subCategory: 'Gotta have a subCategory' };
  }
};

export const EditForm = ({
  activity,
  autoFocus,
  dialogProps = { open: false },
  handleDelete,
  moreExist,
  setActivityItem,
}: Props) => {
  if (!dialogProps.open) {
    return null;
  }

  if (isNil(activity)) {
    return null;
  }

  const {
    category,
    data,
    downloadURL,
    file,
    name,
    subCategory,
    subjects,
    tags,
    timeCreated = Date.now(),
  } = activity;

  console.log({ timeCreated });

  const onSubmit = (partialActivityItem: Omit<ActivityItem, 'imageId'>) => {
    setActivityItem({
      ...activity,
      ...partialActivityItem,
    });
    // todo, clear the fields
  };

  const imgSrc = typeof data === 'string' ? data : downloadURL;

  return (
    <Form
      initialValues={{
        category,
        name,
        subCategory,
        subjects,
        tags,
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <Dialog {...dialogProps}>
          <ClosableDialogTitle onClose={dialogProps.onClose}>Edit Activity</ClosableDialogTitle>
          <DialogContent>
            <div
              style={{
                marginBottom: 14,
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <img
                  src={imgSrc}
                  style={{
                    maxWidth: '100%',
                  }}
                />
                <Button
                  onClick={console.log}
                  startIcon={<ImageSearch />}
                  style={{
                    bottom: 8,
                    position: 'absolute',
                    right: 8,
                  }}
                  variant="contained"
                >
                  Change Image
                </Button>
              </div>
              <Typography color="textSecondary">
                created on {format(new Date(timeCreated), 'MMM do, yyyy h:mmaaaaa\'m')}
                {/* TODO: by {uploadedBy} */}
              </Typography>
            </div>

            <form noValidate onSubmit={handleSubmit}>
              <TextField
                autoFocus={autoFocus}
                label="Name"
                name="name"
                required
              />
              <TextField
                label="Category"
                name="category"
                required
              />
              <TextField
                label="Sub-Category"
                name="subCategory"
                required
              />
              <TextField
                label="Tags"
                name="tags"
              />

              <Autocomplete
                label="Subjects"
                multiple
                name="subjects"
                options={values.subjects ?? []}
                renderInput={params => (
                  <MuiRffTextField
                    label="Subjects"
                    name="subjects"
                    variant="outlined"
                    {...params}
                  />
                )}
                renderTags={(values: string[], getTagProps) => (
                  values.map((option: string, index: number) => (
                    <Chip
                      key={option}
                      label={option}
                      variant="outlined"
                      {...getTagProps({ index })}
                    />
                  ))
                )}
              />

              {/* <TextField
                label="subjects"
                name="subjects"
              /> */}
            </form>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDelete}
              startIcon={<Delete />}
            >
              Delete Activity
            </Button>

            <Button
              onClick={handleSubmit}
              startIcon={<Done />}
              variant="contained"
            >
              {moreExist ? 'Next' : 'Done'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      validate={validate}
    />
  );
};
