import React from 'react';
import {
  DialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps,
  IconButton as MuiIconButton,
  IconButtonProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogTitle);

const IconButton = withStyles(theme => ({
  root: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))(MuiIconButton);

interface Props extends DialogTitleProps {
  onClose?: DialogProps['onClose'];
}

export const ClosableDialogTitle = ({ children, classes, onClose, ...other }: Props) => (
  <DialogTitle disableTypography {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
      <IconButton aria-label="close" onClick={onClose as IconButtonProps['onClick']}>
        <Close />
      </IconButton>
    ) : null}
  </DialogTitle>
);
