import { FileObject } from 'material-ui-dropzone';
import { forEach, pickBy, values } from 'ramda';
import { ExtendedFirebaseInstance } from 'react-redux-firebase';
import { Activity, ActivityAndFile, ActivityAndFileByImageId, ActivityItem, GroupId, UserId } from '../entities';
import { selectImageRootPath } from '../store/firebaseSelectors';
import { imageSize } from 'image-size';

type OnSave = (args: {
  activityItems: ActivityAndFileByImageId;
  firebase: ExtendedFirebaseInstance;
  groupId?: GroupId;
  onFinish: () => void;
  userId: UserId;
}) => (() => void);

export const onSave: OnSave = ({
  activityItems,
  firebase,
  groupId,
  onFinish,
  userId,
}) => () => forEach(activityItem => {
  if (groupId === undefined) {
    return;
  }
  const {
    category,
    file,
    imageId,
    name,
    subCategory,
    subjects,
    tags,
  } = activityItem;

  const rootPath = selectImageRootPath(groupId);

  file.arrayBuffer()
    .then(arrayBuffer => {
      const size = imageSize(Buffer.from(arrayBuffer));

      firebase.uploadFile(rootPath, file, rootPath, {
        documentId: imageId,
        metadataFactory: (uploadRes, firebase, metadata, downloadURL) => ({
          ...pickBy(metadatum => metadatum !== undefined, {
            ...metadata,
            subjects,
            tags,
          }),
          category,
          downloadURL,
          groupId,
          height: size.height,
          imageId,
          name,
          originalName: file.name,
          subCategory,
          uploadedBy: userId,
          width: size.width,
        }),
        name: imageId,
      })
        .then((...args) => {
          console.log('uploaded file', { args });
        })
        .catch((...args) => {
          console.log('error uploading file', { args });
        })
        .finally(() => {
          onFinish();
        });
    })
    .catch(console.log);

}, values(activityItems));

export const readFile = (file: File) => new Promise<FileObject['data']>((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = event => {
    resolve(event?.target?.result as FileObject['data']);
  };
  reader.onerror = event => {
    reader.abort();
    reject(event);
  };
  reader.readAsDataURL(file);
});

export const isAnnotated = (activity: ActivityItem) => (
  activity.name !== null
    && activity.category !== null
    && activity.subCategory !== null
);
