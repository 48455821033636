import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { authReducer } from './authSlice';
import { configReducer } from './configSlice';
import { useDispatch } from 'react-redux';
import { Reducer } from 'redux';
import { databaseReducer } from './databaseSlice';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import firebase from 'firebase';

// side effects.. :(
import 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

firebase.initializeApp({
  apiKey: 'AIzaSyAIxw-Y3fXAa5z9e4tAHhqYHk4PUxrWGok',
  appId: '1:753923626011:web:76f4849256388f996794be',
  authDomain: 'activity-menu.firebaseapp.com',
  databaseURL: 'https://activity-menu.firebaseio.com',
  measurementId: 'G-Z4CXCQX430',
  messagingSenderId: '753923626011',
  projectId: 'activity-menu',
  storageBucket: 'activity-menu.appspot.com',
});

// Initialize other services on firebase instance
firebase.firestore();
firebase.functions();

export const store = configureStore({
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  reducer: {
    auth: authReducer,
    config: configReducer,
    database: databaseReducer,
    firebase: firebaseReducer as Reducer<ReturnType<typeof firebaseReducer>>,
    firestore: firestoreReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
