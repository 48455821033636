import { Button, withStyles } from '@material-ui/core';

export const WarningButton = withStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))(Button);
