import { filter, find, isNil, whereEq } from 'ramda';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Activity, Filters, Group, GroupId, ImageId, User, UserId, UsersByUserId } from '../entities';
import { selectFilters } from './configSlice';
import { RootState } from './store';

export const selectIsLoadingUser = (state: RootState) => !isLoaded(state.firebase.auth);
export const selectAuth = (state: RootState) => state.firebase.auth;
export const selectUserIsLoggedIn = (state: RootState) => !isEmpty(state.firebase.auth);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return -- problem with the upstream types
export const selectAuthError = (state: RootState) => state.firebase.authError;
export const selectUserId = (state: RootState) => state.firebase.auth?.uid;
export const selectDisplayName = (state: RootState) => state.firebase.auth?.displayName;
export const selectGroups = (state: RootState): Group[] | null => {
  if (isNil(state.firestore?.data?.groups)) {
    return null;
  }
  return Object.values(state.firestore?.data?.groups as { [groupId in GroupId]: Group }).filter(group => (
    group.members.includes(state.firebase.auth?.uid)
  ));
};
export const selectGroupMembers = (groupId: GroupId) => (state: RootState): UserId[] | null => {
  if (isNil(groupId)) {
    return null;
  }

  const groups = selectGroups(state);
  if (isNil(groups)) {
    return null;
  }

  const group = find(whereEq({ groupId }), groups);
  if (isNil(group)) {
    return null;
  }

  return group.members;
};
export const selectGroupName = (groupId: GroupId) => (state: RootState): string | null => {
  if (isNil(groupId)) {
    return null;
  }

  const groups = selectGroups(state);
  if (isNil(groups)) {
    return null;
  }

  const group = find(whereEq({ groupId }), groups);
  if (isNil(group)) {
    return null;
  }

  return group.name;
};

export const selectActiveGroup = (state: RootState): Group | null => {
  const groups = selectGroups(state);
  if (groups === null) {
    return null;
  }
  return groups[0];
};
export const selectActivities = (state: RootState): Activity[] | null => (
  state.firestore?.data?.images ? Object.values(state.firestore?.data?.images) : null
);

const selectActivitiesWithFilters = (filters: Filters, activities: Activity[]) => {
  const { category, subCategory } = filters;
  const noCategory = isNil(category);
  const noSubCategory = isNil(subCategory);
  if (noCategory && noSubCategory) {
    return activities;
  }

  const spec = {
    ...(noCategory ? {} : { category }),
    ...(noSubCategory ? {} : { subCategory }),
  };
  return filter(whereEq(spec), activities);
};

export const selectFilteredActivityImages = (state: RootState): Activity[] | null => {
  const activities = selectActivities(state);
  if (activities === null) {
    return null;
  }
  const filters = selectFilters(state);
  return selectActivitiesWithFilters(filters, activities);
};

export const selectActivityCount = (state: RootState) => (filters: Filters): number | null => {
  const activities = selectActivities(state);
  if (activities === null) {
    return null;
  }

  return selectActivitiesWithFilters(filters, activities).length;
};

export const selectImageFullPath = ({
  groupId,
  imageId,
}: {
  groupId: GroupId;
  imageId: ImageId;
}) => `groups/${groupId}/images/${imageId}`;
export const selectImageRootPath = (groupId: GroupId) => `groups/${groupId}/images`;

export const selectUsers = (state: RootState): UsersByUserId | null => state.firestore?.data?.users ?? null;
