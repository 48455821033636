import React, { FC } from 'react';
import {
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectGroups } from '../store/firebaseSelectors';
import { Alert } from '@material-ui/lab';
import { isNil, map } from 'ramda';
import { Group } from '../entities';
import { isEmpty } from 'react-redux-firebase';
import { InviteUserButton } from './InviteUserButton';
import { EditGroupButton } from './EditGroupButton';
import { MembersList } from './MembersList';
import { selectDebugMode } from '../store/configSlice';
import { InlineCode } from './InlineCode';

const CardActions = withStyles(({
  root: {
    justifyContent: 'flex-end',
  },
}))(MuiCardActions);

const Card = withStyles(({
  root: {
    width: 375,
  },
}))(MuiCard);

export const GroupCard = ({ group: { groupId, name } }: { group: Group }) => {
  const debugMode = useSelector(selectDebugMode);
  return (
    <Card>
      <CardContent>
        <Typography component="h2" gutterBottom variant="h5">{name}</Typography>
        {debugMode && <InlineCode>{groupId}</InlineCode>}
        <MembersList groupId={groupId} />
      </CardContent>
      <CardActions>
        <EditGroupButton groupId={groupId} groupName={name} />
        <InviteUserButton groupId={groupId} />
      </CardActions>
    </Card>
  );
};

export const GroupCards: FC = () => {
  const groups = useSelector(selectGroups);

  if (isEmpty(groups) || isNil(groups)) {
    return (
      <Alert severity="info">
        You don&apos;t belong to any groups yet.
      </Alert>
    );
  }

  return (
    <Grid container direction="row" spacing={3}>
      {map(group => (
        <Grid item key={group.groupId}>
          <GroupCard group={group} />
        </Grid>
      ), groups)}
    </Grid>
  );
};
