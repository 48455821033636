import React, { FC } from 'react';

import { ActivitiesPage } from './pages/ActivitiesPage';
import { AppBar } from './AppBar';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ConfigPage } from './pages/ConfigPage';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveGroup } from '../store/firebaseSelectors';
import { selectDebugMode, setDebugMode } from '../store/configSlice';
import { BreakpointIndicator } from 'mui-breakpoint-indicator';

export const App: FC = () => {
  const group = useSelector(selectActiveGroup);
  const debugMode = useSelector(selectDebugMode);
  const dispatch = useDispatch();

  window.setDebugMode = (debugMode: boolean) => {
    dispatch(setDebugMode(debugMode));
  };

  useFirestoreConnect([
    { collection: 'groups' },
    { collection: 'users' },
  ]);

  useFirestoreConnect(...(group === null || group === undefined ? [] : [
    {
      collection: 'groups',
      doc: group.groupId,
      storeAs: 'images',
      subcollections: [
        {
          collection: 'images',
        },
      ],
    },
  ]));

  return (
    <div>
      <BrowserRouter>
        <AppBar />
        <BreakpointIndicator visible={debugMode} />

        <Switch>
          <Route exact path="/">
            <ActivitiesPage />
          </Route>

          <Route path="/config">
            <ConfigPage />
          </Route>
        </Switch>

      </BrowserRouter>
    </div>
  );
};
