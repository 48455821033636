import { Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText as MuiListItemText, Typography, withStyles } from '@material-ui/core';
import { map } from 'ramda';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { GroupId } from '../entities';
import { RemoveUserIconButton } from './RemoveUserIconButton';
import { selectGroupMembers, selectUsers } from '../store/firebaseSelectors';
import { selectDebugMode } from '../store/configSlice';
import { InlineCode } from './InlineCode';

const ListItemText = withStyles({
  secondary: {
    whiteSpace: 'break-spaces',
  },
})(MuiListItemText);

interface Props {
  groupId: GroupId;
  showDelete?: boolean;
}

export const MembersList: FC<Props> = ({ groupId, showDelete }) => {
  const members = useSelector(selectGroupMembers(groupId));
  const users = useSelector(selectUsers);
  const debugMode = useSelector(selectDebugMode);

  if (!members) {
    return <div>no users found in this group</div>;
  }

  if (!users) {
    return <div>no users found in this group</div>;
  }

  const annotatedMembers = map(userId => ({
    avatarUrl: users[userId]?.avatarUrl,
    displayName: users[userId]?.displayName,
    email: users[userId]?.email,
    userId,
  }), members);

  return (
    <List>
      {map(({ avatarUrl, displayName, email, userId }) => (
        <ListItem key={userId}>
          <ListItemAvatar>
            <Avatar src={avatarUrl} />
          </ListItemAvatar>

          <ListItemText>
            <Typography variant="body1">{displayName}</Typography>
            <Typography color="textSecondary" variant="body2">{email}</Typography>
            {debugMode && <InlineCode>{groupId}</InlineCode>}
          </ListItemText>


          {showDelete && (
            <ListItemSecondaryAction>
              <RemoveUserIconButton
                displayName={displayName}
                groupId={groupId}
                userId={userId}
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ), annotatedMembers)}
    </List>
  );
};
