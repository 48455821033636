import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface AuthState {
  user: null | {
    uid: string;
    displayName: string;
    photoURL: string;
    email: string;
  };
  modalVisible: boolean;
}

export const authSlice = createSlice({
  initialState: {
    modalVisible: false,
    user: null,
  } as AuthState,
  name: 'auth',
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState['user']>) => ({
      ...state,
      user: action.payload,
    }),
    setModalVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      modalVisible: action.payload,
    }),
  },
});

export const { setAuth, setModalVisible } = authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthModalVisible = (state: RootState) => state.auth.modalVisible;

export const { reducer: authReducer } = authSlice;
