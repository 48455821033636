import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Dialog } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthModalVisible, setModalVisible } from '../store/authSlice';
import { selectAuth } from '../store/firebaseSelectors';
import firebase from 'firebase/app';
import { isNil } from 'ramda';
import { ClosableDialogTitle } from './ClosableDialogTitle';

export const SignInScreen = () => {
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const authModalVisible = useSelector(selectAuthModalVisible);

  if (isNil(auth)) {
    return null;
  }

  const onClose = () => {
    dispatch(setModalVisible(false));
  };

  if (!authModalVisible) {
    return null;
  }

  return (
    <Dialog open>
      <ClosableDialogTitle onClose={onClose}>Sign In</ClosableDialogTitle>
      <StyledFirebaseAuth
        firebaseAuth={firebase.auth()}
        uiConfig={{
          callbacks: {
            signInSuccessWithAuthResult: authResult => {
              onClose();

              // @ts-expect-error not sure why
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call -- not sure why
              firebase.handleRedirectResult(authResult).then(() => {
                console.log('done handing thing');
              });
              return false;
            },
          },
          signInFlow: 'popup',
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          ],
          signInSuccessUrl: '/',
        }}
      />
    </Dialog>
  );
};
